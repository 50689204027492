<template>
  <v-container class="d-flex align-items-center min-vh-100">
    <v-row class="w-100 justify-content-center">
      <v-col md="6">
        <div class="w-100">
        <div class="clearfix">
          <h1 class="float-left display-3 ms-4">404</h1>
          <h4 class="pt-3">صفحه مورد نظر یافت نشد</h4>
          <p class="text-muted">احتمالا لینکی که قصد دسترسی به آن را دارید حذف شده است و یا تغییر کرده است</p>
        </div>
        <v-input
          class="mb-3"
          placeholder="What are you looking for?"
        >
          <template #prepend-content><v-icon name="cil-magnifying-glass"/></template>
          <template #append>
            <v-btn @click='gotoHome()' color="info">بازگشت به صفحه نخست</v-btn>
          </template>
        </v-input>
      </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Page404',
  methods: {
    gotoHome() {
      this.$router.push("/");
    }
  },
}
</script>
